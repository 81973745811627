import React, { useState, useEffect } from "react";
import "./CookieConsent.scss";
import { useTranslation } from "react-i18next";

const CookieConsent = () => {
  const { t } = useTranslation("CookieConsent", { useSuspense: false });
  const [consent, setConsent] = useState(localStorage.getItem("cookieConsent"));
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Initialize analytics if consent was previously given
  useEffect(() => {
    if (consent === "accepted") {
      // Initialize GA4 for returning users who already consented
      window.gtag("js", new Date());
      window.gtag("config", "G-4GLGHSCT2Q");
      console.log("Analytics initialized for returning user with consent");
    }
  }, [consent]);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setConsent("accepted");
    setIsSettingsOpen(false);

    // IMPORTANT: Remove the disable flag that's blocking GA
    window["ga-disable-G-4GLGHSCT2Q"] = false;

    // Load the GA script dynamically if it's not already loaded
    if (!window.gtag || typeof window.gtag !== "function") {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-4GLGHSCT2Q";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
          window.dataLayer.push(arguments); // Changed from dataLayer.push to window.dataLayer.push
        };
        window.gtag("js", new Date());
        window.gtag("config", "G-4GLGHSCT2Q");
        console.log("GA initialized dynamically after consent");

        // Send initial pageview
        window.gtag("event", "page_view", {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname + window.location.search,
        });
      };
    } else {
      // If gtag is already available, just initialize
      window.gtag("js", new Date());
      window.gtag("config", "G-4GLGHSCT2Q");
      console.log("GA initialized with existing script after consent");

      // Send initial pageview
      window.gtag("event", "page_view", {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname + window.location.search,
      });
    }
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setConsent("declined");
    setIsSettingsOpen(false);

    // Remove Google Analytics cookies
    deleteCookies();

    console.log("Cookies declined and cleared");
  };

  // Add this new helper function to remove cookies
  const deleteCookies = () => {
    // First disable GA tracking
    window["ga-disable-G-4GLGHSCT2Q"] = true;

    // Get all cookies
    const cookies = document.cookie.split(";");

    // Try multiple deletion approaches for GA cookies
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();

      if (name.startsWith("_ga")) {
        // Try all common domain/path combinations to ensure deletion
        const domain = window.location.hostname;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain}`;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${domain}`;
      }
    }

    // Clear localStorage items
    for (let key in localStorage) {
      if (key.startsWith("_ga")) {
        localStorage.removeItem(key);
      }
    }
  };

  // New function to open settings
  const openSettings = () => {
    setIsSettingsOpen(true);
  };

  // The banner is shown either for first-time visitors or when settings are open
  // If there's consent and settings aren't open, show just the settings button
  if (consent && !isSettingsOpen) {
    return (
      <div className="cookie-settings-button">
        <button onClick={openSettings}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12q0-1.875.725-3.675T4.75 5.112t3.125-2.275t4-.862q.525 0 1.075.05t1.125.175q-.225 1.125.15 2.125t1.125 1.662t1.788.913t2.137-.125q-.65 1.475.188 2.825T21.95 11q.025.275.038.512t.012.513q0 2.05-.788 3.862t-2.137 3.175t-3.175 2.15T12 22m-1.5-12q.625 0 1.063-.437T12 8.5t-.437-1.062T10.5 7t-1.062.438T9 8.5t.438 1.063T10.5 10m-2 5q.625 0 1.063-.437T10 13.5t-.437-1.062T8.5 12t-1.062.438T7 13.5t.438 1.063T8.5 15m6.5 1q.425 0 .713-.288T16 15t-.288-.712T15 14t-.712.288T14 15t.288.713T15 16m-3 4q3.05 0 5.413-2.1T20 12.55q-1.25-.55-1.963-1.5t-.962-2.125q-1.925-.275-3.3-1.65t-1.7-3.3q-2-.05-3.512.725T6.037 6.688T4.512 9.325T4 12q0 3.325 2.338 5.663T12 20m0-8.1"
            ></path>
          </svg>
          {/* {t("cookieConsent.settings")} */}
        </button>
      </div>
    );
  }

  // If there's no consent OR settings are open, show the full banner
  if (!consent || isSettingsOpen) {
    return (
      <div className="cookie-banner">
        <div className="cookie-content">
          <p>{t("cookieConsent.message")}</p>
          <div className="cookie-buttons">
            <button className="btn-accept" onClick={handleAccept}>
              {t("cookieConsent.accept")}
            </button>
            <button className="btn-decline" onClick={handleDecline}>
              {t("cookieConsent.decline")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null; // Fallback return
};

export default CookieConsent;
