import React, { useContext, useEffect, lazy, Suspense } from "react";
import { ThemeContext } from "./Theme";
import { Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Hero from "./components/Hero/Hero";
import Divider from "./components/Divider/Divider";
import i18next from "i18next";
import CookieConsent from "./components/CookieConsent/CookieConsent";

const Projects = lazy(() => import("./components/Projects/Projects"));
const Skills = lazy(() => import("./components/Skills/Skills"));
const AboutMe = lazy(() => import("./components/AboutMe/AboutMe"));
const Contact = lazy(() => import("./components/Contact/Contact"));
function App() {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();

  useEffect(() => {
    // Theme changes
    document.body.classList.remove("light");
    document.body.classList.remove("dark");
    document.body.classList.add(theme);

    const hasConsent = localStorage.getItem("cookieConsent") === "accepted";
    if (!hasConsent) {
      // Disable GA if no consent
      window["ga-disable-G-4GLGHSCT2Q"] = true;
    }

    // Track page views only if consent was given
    if (localStorage.getItem("cookieConsent") === "accepted" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname + location.search,
      });
    }
  }, [theme, location]);

  return (
    <HelmetProvider>
      <div className={`${theme}-theme`}>
        <Helmet>
          <title>András Németh | Kreatív fejlesztő</title>
          <meta
            name="description"
            content="Kreatív full-stack webfejlesztő vagyok, aki szenvedéllyel készít weboldalakat és webalkalmazásokat. Tekintsd meg portfóliómat és legújabb projekteimet."
          />
          <meta
            name="keywords"
            content="Web fejlesztő, Front-end fejlesztő, React, JavaScript, Responsive design, Web development, Web developer, Portfolio, András Németh"
          />

          <html lang={i18next.language} />
          <link rel="alternate" hrefLang="en" href="https://andrasdev.hu/" />
          <link rel="alternate" hrefLang="hu" href="https://andrasdev.hu/" />
          <link
            rel="alternate"
            hrefLang="x-default"
            href="https://andrasdev.hu/"
          />
          {/* Open Graph Tags for Social Media */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://andras.dev/" />
          <meta
            property="og:title"
            content="András Németh - Kreatív webfejlesztő portfolió"
          />
          <meta
            property="og:description"
            content="Front-end fejlesztő vagyok, aki szakértője a React, JavaScript és a reszponzív design területén. Tekintsd meg portfóliómat és legújabb projekteimet."
          />

          {/* Canonical URL */}
          <link rel="canonical" href="https://andrasdev.hu/" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Person",
              name: "András Németh",
              url: "https://andrasdev.hu",
              /*               image: "https://andrasdev.hu/profile-image.jpg", */
              jobTitle: "Kreatív webfejlesztő",
              worksFor: {
                "@type": "Organization",
                name: "Freelance",
              },
              sameAs: [
                "https://www.linkedin.com/in/andrasnemeth939/",
                "https://github.com/andrisnemeth",
              ],
            })}
          </script>
        </Helmet>
        <NavigationBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Suspense
                  fallback={
                    <div className="loading-placeholder">Loading...</div>
                  }
                >
                  <Projects />
                  <Skills />
                  <div style={{ position: "relative" }}>
                    <AboutMe />
                    <Divider />
                  </div>
                  <Contact />
                </Suspense>
              </>
            }
          />
        </Routes>
        <CookieConsent />
      </div>
    </HelmetProvider>
  );
}

export default App;
