import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import NavigationBar files
import NavigationBar_en from "./locales/en/NavigationBar.json";
import NavigationBar_hu from "./locales/hu/NavigationBar.json";
// import Hero files
import Hero_en from "./locales/en/Hero.json";
import Hero_hu from "./locales/hu/Hero.json";
// import Projects files
import Projects_en from "./locales/en/Projects.json";
import Projects_hu from "./locales/hu/Projects.json";
// import Skills files
import Skills_en from "./locales/en/Skills.json";
import Skills_hu from "./locales/hu/Skills.json";
// import AboutMe files
import AboutMe_en from "./locales/en/AboutMe.json";
import AboutMe_hu from "./locales/hu/AboutMe.json";
// import Contact files
import Contact_en from "./locales/en/Contact.json";
import Contact_hu from "./locales/hu/Contact.json";
// import CookieConsent files
import CookieConsent_en from "./locales/en/CookieConsent.json";
import CookieConsent_hu from "./locales/hu/CookieConsent.json";

const resources = {
  en: {
    NavigationBar: NavigationBar_en,
    Hero: Hero_en,
    Projects: Projects_en,
    Skills: Skills_en,
    AboutMe: AboutMe_en,
    Contact: Contact_en,
    CookieConsent: CookieConsent_en,
  },
  hu: {
    NavigationBar: NavigationBar_hu,
    Hero: Hero_hu,
    Projects: Projects_hu,
    Skills: Skills_hu,
    AboutMe: AboutMe_hu,
    Contact: Contact_hu,
    CookieConsent: CookieConsent_hu,
  },
};

let language = localStorage.getItem("language");
if (!language) {
  language = window.navigator.language.startsWith("hu") ? "hu" : "en";
  localStorage.setItem("language", language);
}

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});
